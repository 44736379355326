<template>
  <div>
    <b-row class="match-height">
      <!-- Trofeu -->
      <b-col
        xl="4"
        md="6"
      >
        <b-card
          v-if="nameUser"
          class="card-congratulation-medal"
        >
          <h5>Bem vindo 🎉 {{ nameUser }}!</h5>
          <b-card-text class="font-small-3">
            Informaçoes do seu BOT
          </b-card-text>
          <div v-if="botInfo">
            <h3 class="mb-75 mt-2 pt-50">
              <b-link>
                {{ botInfo.number }}
              </b-link>
            </h3>
            <p>
              VALOR DO BOT: <strong>R$ {{ botInfo.value }}</strong>
            </p>
            <p>
              VENDEDOR: <strong>{{ botInfo.username }}</strong>
            </p>
            <p>
              <b-link
                target="_blank"
                href="http://wa.me/5581992106048"
              >
                <span>ENTRAR EM CONTATO</span>
              </b-link>
            </p>
            <hr>
          </div>
          <div v-else>
            <p>Nenhum bot encontrado.</p>
          </div>
          <b-img
            :src="require('@/assets/images/illustration/badge.svg')"
            class="congratulation-medal"
            alt="Medal Pic"
          />
        </b-card>
      </b-col>
      <!-- Trofeu -->

      <!-- Listagem dos Grupos -->
      <b-col style="width: 50%">
        <b-card>
          <div class="card-header">
            <h4 class="card-title">
              Meus Grupos
            </h4>
            <div class="d-flex align-items-center">
              <p class="card-text font-small-2 mr-25 mb-0">
                Atualizado a 1m atrás
              </p>
            </div>
          </div>
          <div
            v-if="groups"
            class="col-lg-12 col-12"
          >
            <div class="card card-company-table">
              <div class="card-body p-0">
                <div class="table-responsive b-table-sticky-header">
                  <table
                    class="table b-table"
                  >
                    <thead />
                    <thead>
                      <tr>
                        <th>GRUPO</th>
                        <th>LINK</th>
                        <th>STATUS</th>
                        <th>VENCIMENTO</th>
                        <th>PAGAMENTO</th>
                      </tr>
                    </thead>
                    <tbody
                      v-for="group in groups"
                      :key="group.id"
                    >

                      <tr>
                        <td>
                          <div class="d-flex align-items-center">
                            <div class="avatar rounded">
                              <div class="avatar-content">
                                <img
                                  src="@/assets/images/icons/star.svg"
                                  alt="Star svg"
                                >
                              </div>
                            </div>
                            <div>
                              <div class="font-weight-bolder">
                                {{ group.name.toUpperCase() }}
                              </div>
                              <div class="font-small-2 text-muted">
                                {{ group.type.toUpperCase() }}
                              </div>
                            </div>
                          </div>
                        </td>
                        <td>
                          <div class="d-flex align-items-center">
                            <a
                              target="_bank"
                              href="<awdawd"
                            >{{ group.link }}</a>
                          </div>
                        </td>
                        <td class="text-nowrap">
                          <div class="d-flex flex-column">
                            <span class="font-weight-bolder mb-25">
                              <b-badge
                                v-if="group.status == 'pendente'"
                                variant="warning"
                              >
                                {{ group.status.toUpperCase() }}
                              </b-badge>
                              <b-badge
                                v-if="group.status == 'aprovado'"
                                variant="success"
                              >
                                {{ group.status.toUpperCase() }}
                              </b-badge>
                              <b-badge
                                v-if="group.status == 'recusado'"
                                variant="danger"
                              >
                                {{ group.status.toUpperCase() }}
                              </b-badge>
                              <b-badge
                                v-if="group.status == 'banido'"
                                variant="dark"
                              >
                                {{ group.status.toUpperCase() }}
                              </b-badge>
                              <b-badge
                                v-if="group.status == 'vencido'"
                                variant="danger"
                              >
                                {{ group.status.toUpperCase() }}
                              </b-badge>
                            </span>
                          </div>
                        </td>
                        <td>
                          <span
                            v-if="group.date_end == null"
                            class="font-weight-bolder mb-25"
                          >
                            <b-badge
                              variant="dark"
                            >
                              NAO INICIADO
                            </b-badge>
                          </span>
                          <span
                            v-else
                            class="font-weight-bolder mb-25"
                          >
                            <b-badge
                              variant="warning"
                            >
                              {{ formatDate(group.date_end) }}
                            </b-badge>
                          </span>
                        </td>
                        <td>
                          <span
                            v-if="group.statuspayment == 'pendente'"
                            class="font-weight-bolder mb-25"
                          >
                            <b-badge
                              variant="dark"
                            >
                              NAO PAGO
                            </b-badge>
                          </span>
                          <span
                            v-if="group.statuspayment == 'aprovado'"
                            class="font-weight-bolder mb-25"
                          >
                            <b-badge
                              variant="success"
                            >
                              PAGO
                            </b-badge>
                          </span>
                          <span
                            v-if="group.statuspayment == 'aprovado_ext'"
                            class="font-weight-bolder mb-25"
                          >
                            <b-badge
                              variant="success"
                            >
                              PAGO
                            </b-badge>
                          </span>
                          <span
                            v-if="group.statuspayment == 'recusado'"
                            class="font-weight-bolder mb-25"
                          >
                            <b-badge
                              variant="danger"
                            >
                              RECUSADO
                            </b-badge>
                          </span>
                          <span
                            v-if="!group.statuspayment"
                            class="font-weight-bolder mb-25"
                          >
                            <b-badge
                              variant="warning"
                            >
                              SEM ORDEM
                            </b-badge>
                          </span>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          <div v-else>
            <b-alert
              show
              variant="primary"
            >
              <div class="alert-body">
                <feather-icon
                  class="mr-25"
                  icon="StarIcon"
                />
                <span
                  class="ml-25"
                >Voce nao possui nenhum bot cadastrado.</span>
              </div>
            </b-alert>
            <b-button
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="outline-primary"
              :to="{ name: 'cadastrar-grupo' }"
            >
              Cadastrar Grupo
            </b-button>
          </div>
        </b-card>
      </b-col>
      <!-- Listagem dos Grupos -->
    </b-row>

    <!-- Listagem das Ordens -->
    <b-card>
      <div class="row match-height">
        <div class="col-lg-12 col-12">
          <div class="card card-company-table">
            <div class="card-body p-0">

              <b-alert
                show
                variant="info"
              >
                <div class="alert-body ">
                  <feather-icon
                    class="mr-25"
                    icon="StarIcon"
                  />
                  <span
                    class="ml-10"
                  >Histórico de Ordens</span>
                </div>
              </b-alert>
              <div
                v-if="orders[0]"
                class="table-responsive"
              >
                <table class="table">
                  <thead />
                  <thead>
                    <tr>
                      <th>NOME DO PAGADOR</th>
                      <th>VALOR PAGO</th>
                      <th>DATA DE PGMT </th>
                      <th>STATUS</th>

                    </tr>
                  </thead>
                  <tbody
                    v-for="order in orders"
                    :key="order.id"
                  >

                    <tr>
                      <td>
                        <div class="d-flex align-items-center">
                          <div class="avatar rounded">
                            <div class="avatar-content">
                              <img
                                src="@/assets/images/icons/star.svg"
                                alt="Star svg"
                              >
                            </div>
                          </div>
                          <div>
                            <div class="font-weight-bolder">
                              {{ order.name }}
                            </div>
                          </div>
                        </div>
                      </td>
                      <td>
                        <div class="d-flex align-items-center">
                          <div class="avatar bg-light-primary mr-1">
                            <div class="avatar-content">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24px"
                                height="24px"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                class="feather feather-dollar-sign"
                              ><line
                                x1="12"
                                y1="1"
                                x2="12"
                                y2="23"
                              /><path d="M17 5H9.5a3.5 3.5 0 0 0 0 7h5a3.5 3.5 0 0 1 0 7H6" /></svg>
                            </div>
                          </div>
                          <a
                            target="_bank"
                            href="#"
                          >R$ {{ order.value }}</a>
                        </div>
                      </td>
                      <td class="text-nowrap">
                        <div class="d-flex flex-column">
                          <span class="font-weight-bolder mb-25">
                            {{ formatDate(order.created) }}
                          </span>
                        </div>
                      </td>
                      <td class="text-nowrap">
                        <div class="d-flex flex-column">
                          <span class="font-weight-bolder mb-25">
                            <b-badge
                              v-if="order.status == 'pendente'"
                              variant="warning"
                            >
                              {{ order.status.toUpperCase() }}
                            </b-badge>
                            <b-badge
                              v-if="order.status == 'aprovado'"
                              variant="success"
                            >
                              {{ order.status.toUpperCase() }}
                            </b-badge>
                            <b-badge
                              v-if="order.status == 'analise'"
                              variant="info"
                            >
                              {{ order.status.toUpperCase() }}
                            </b-badge>
                            <b-badge
                              v-if="order.status == 'recusado'"
                              variant="danger"
                            >
                              {{ order.status.toUpperCase() }}
                            </b-badge>
                          </span>
                        </div>
                      </td>

                    </tr>

                  </tbody>
                </table>
              </div>
              <div v-else>
                <b-alert
                  show
                  variant="danger"
                >
                  <div class="alert-body ">
                    <feather-icon
                      class="mr-25"
                      icon="StarIcon"
                    />
                    <span
                      class="ml-10"
                    >Nenhuma ordem de pagamento foi encontrada </span>
                  </div>
                </b-alert>
              </div>
            </div>
          </div>
        </div>
      </div>
    </b-card>
    <!-- Listagem das Ordens -->
  </div>
</template>

<script>
import {
  BCard,
  BCardText,
  BLink,
  BImg,
  BRow,
  BCol,
  BAlert,
  BButton,
  BBadge,
} from 'bootstrap-vue'
import funcs from '@/services/func.service'
import Ripple from 'vue-ripple-directive'
import moment from 'moment'

const user = JSON.parse(localStorage.getItem('user'))

export default {
  components: {
    BCard,
    BCardText,
    BLink,
    BImg,
    BRow,
    BCol,
    BAlert,
    BButton,
    BBadge,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      data: [],
      nameUser: '',
      botInfo: [],
      stickyHeader: true,
      groups: [],
      orders: [],
    }
  },
  computed: {},
  mounted() {
    this.fetchUserLoggedIn()
    this.fetchBotReseller()
    this.fetchUserGroups()
    this.fetchUserOrders()
  },
  methods: {
    fetchUserLoggedIn() {
      funcs.fetchUser(user.id).then(success => {
        console.log(success)
        if (success.data[0].username) {
          const { name } = success.data[0]
          this.nameUser = name
        }
      })
    },

    fetchBotReseller() {
      funcs.fetchBotReseller(user.id).then(success => {
        console.log(success)
        if (success.data[0]) {
          const returnRequest = success.data[0]
          this.botInfo = returnRequest
        }
      })
    },

    fetchUserGroups() {
      funcs.fetchUserGroups(user.id).then(success => {
        console.log(success)
        if (success.data[0]) {
          const returnRequest = success.data
          this.groups = returnRequest
        }
      })
    },

    fetchUserOrders() {
      funcs.fetchUserOrders(user.id).then(success => {
        console.log(success)
        if (success.data[0]) {
          const returnRequest = success.data
          this.orders = returnRequest
        }
      })
    },

    formatDate(date) {
      const newdate = `${moment(date)
        .utc()
        .format('DD/MM/YYYY')}`
      this.date_end = newdate
      return newdate
    },
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/pages/dashboard-ecommerce.scss";
</style>
